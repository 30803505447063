<template>
    <div>
        <div class="sm:flex justify-between">
            <div class="font-bold text-primary text-xl">Waiver List</div>
            <div class="flex">
                <a :href="exportLink" target="_blank"
                    class="flex items-center bg-gray-200 hover:bg-gray-300 py-1 px-3 rounded-xl">
                    <div class="font-semibold text-sm">Export</div>
                </a>
                <button
                    @click="$router.push({ name: 'WaiverAdminForm' })"
                    class="flex items-center bg-gray-200 hover:bg-gray-300 py-1 px-3 rounded-xl"  v-if="$store.state.user.admin != '1'"
                >
                    <font-awesome-icon class="mr-2" icon="fa-regular fa-circle-xmark" :transform="{ rotate: 45 }"/>
                    <div class="font-semibold text-sm">Add Waiver</div>
                </button>
            </div>
        </div>
        <not-found-card v-if="waivers.length == 0" class="mt-8"></not-found-card>
        <table v-else class="table-auto w-full mt-8">
            <thead class="text-primary">
                <tr>
                    <th class="text-left text-x sm:text-base">Date</th>
                    <th class="text-left text-x sm:text-base">Name</th>
                    <th class="text-left text-x sm:text-base">Phone</th>
                    <th class="text-left text-x sm:text-base">Email</th>
                    <th class="text-left text-x sm:text-base">Id Card Number</th>
                    <th class="text-left text-x sm:text-base">Emergency Number</th>
                    <th class="text-x sm:text-base">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="waiver in waivers" :key="waiver.id_alamat_user" class="font-medium border-t-2 text-x sm:text-base">
                    <td>{{ $moment(waiver.tanggal).format("DD MMM YYYY") }}</td>
                    <td>{{ waiver.name }}</td>
                    <td>{{ waiver.phone_number }}</td>
                    <td>{{ waiver.email }}</td>
                    <td>{{ waiver.nik }}</td>
                    <td>{{ waiver.emergency }}</td>
                    <td class="text-center py-3 text-x sm:text-base">
                        <font-awesome-icon
                            @click="openEditPage(waiver)"
                            class="cursor-pointer mx-4"
                            icon="fa-solid fa-pen-to-square"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="flex flex-row justify-between text-primary text-lg font-bold mt-2" v-if="waivers.length > 0">
            <div>
            <span v-if="page.prev != '' && page.prev != null" class="cursor-pointer" @click="handlePaginate('prev')">
                <font-awesome-icon icon="fa-solid fa-arrow-left" />
                Prev Page
            </span>
            </div>
            <div>
            <span v-if="page.next != '' && page.next != null" class="cursor-pointer" @click="handlePaginate('next')">
                Next Page
                <font-awesome-icon icon="fa-solid fa-arrow-right" />
            </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WaiverAdminList',

        components: {
            NotFoundCard: () => import('@/components/layout/NotFoundCard.vue')
        },

        data() {
            return {
                waivers: [],
                page: {
                    prev: "",
                    next: ""
                },
            };
        },

        created() {
            this.loadWaiver();
        },
        
        computed: {
            exportLink() {
                if(this.$store.state.user.admin == 1 || this.$store.state.user.id_user == 4){
                    return `${this.$apiTripweWeb}/export/waiver/all`;
                }else{
                    return `${this.$apiTripweWeb}/export/waiver/${this.$store.state.user.id_user}`;
                }
            },
        },

        methods: {
            async loadWaiver(url = `${this.$apiTripweWeb}/waiver-admin/list`) {
                this.$store.commit('setOverlayLoading', true);

                try {
                    const response = await this.$http.get(
                        url);

                    if (response.data.status != '200') {
                        throw response.data.message;
                    }

                    this.waivers = response.data.data.item;
                    this.page.prev = response.data.data.prev_page;
                    this.page.next = response.data.data.next_page;
                } catch (error) {
                    this.$toasted.global.error(error);
                }

                this.$store.commit('setOverlayLoading', false);
            },
            handlePaginate(type) {
            if (type == 'next') {
                this.loadWaiver(this.page.next);
            } else {
                this.loadWaiver(this.page.prev);
            }
            },
            openEditPage(waiver) {
                this.$router.push({ name: 'WaiverAdminForm', params: { waiver } });
            },
        }
    };
</script>
